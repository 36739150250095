import React, { useReducer } from 'react'
import BuynowContext from './buynowContext'
import BuynowReducer from './buynowReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_SEARCH,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_CART,
    CLEAR_SINGLE_CART,
} from './buynowTypes'

const BuynowState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(BuynowReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addToCart = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'addToCart', formData, '', 'buynow')])
            resp.commonResponse(res.data, 'addToCart')
        } catch (err) {
            resp.commonErrorResponse('addToCart')
        }
    }

    const refreshCart = async (formData, noAlert, from) => {
        from = from ? from : 'refreshCart'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'refreshCart', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getSingleCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'checkout', formData, '', 'buynow')])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'buynow')])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('cartsearch')
        }
    }

    // Register User
    const changeOnCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'buynow'),
            ])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const cancelItem = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const saveAddress = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'saveAddress', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartaddress')
        } catch (err) {
            resp.commonErrorResponse('cartaddress')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearSingleCart = () =>
        dispatch({
            type: CLEAR_SINGLE_CART,
        })

    return (
        <BuynowContext.Provider
            value={{
                search_allproducts: state.search_allproducts,
                search_singlecart: state.search_singlecart,
                responseStatus: state.responseStatus,
                addToCart,
                refreshCart,
                getSingleCartProducts,
                clearResponse,
                saveAddress,
                getAllCartProducts,
                changeOnCart,
                cancelItem,
                clearSingleCart,
            }}
        >
            {props.children}
        </BuynowContext.Provider>
    )
}

export default BuynowState
