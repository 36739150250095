import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_STATIC_PAGE,
    GET_INVITE_DATA,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_COMPANY_OWNER_DATA,
    GET_SKYFLOW_CARDS,
} from './userTypes'

const UserState = (props) => {
    const initialState = {
        responseStatus: null,
        static_page: {},
        invite_data: {
            record: null,
        },
        company_owner_data: {
            record: null,
        },
        skyflow_cards: null,
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const updateProfile = async (formData, noAlert, from) => {
        from = from ? from : 'updateProfile'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateProfile', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updatePreference = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updatePreference', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'updatePreference')
        } catch (err) {
            resp.commonErrorResponse('updatePreference')
        }
    }

    const forgotPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgotPassword', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'forgotPassword')
        } catch (err) {
            resp.commonErrorResponse('forgotPassword')
        }
    }

    const sendPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendPhoneVerifyCode')
        }
    }

    const verifyPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('verifyPhoneVerifyCode')
        }
    }

    const resetPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resetPassword', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'resetPassword')
        } catch (err) {
            resp.commonErrorResponse('resetPassword')
        }
    }

    const sendEmailVerifyLink = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendEmailVerifyLink', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendEmailVerifyLink')
        } catch (err) {
            resp.commonErrorResponse('sendEmailVerifyLink')
        }
    }

    const verifyEmail = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'verifyEmail', formData, '', 'user')])
            resp.commonResponse(res.data, 'verifyEmail')
        } catch (err) {
            resp.commonErrorResponse('verifyEmail')
        }
    }
    const verifyAddress = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'isValidAddress', formData, '', 'tax/avalara'),
            ])
            resp.commonResponse(res.data, 'isValidAddress')
        } catch (err) {
            resp.commonErrorResponse('isValidAddress')
        }
    }
    const getStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'show_static_content', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: res.data.data[0],
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const submitContactUs = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'submitContactUs', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'submitContactUs')
        } catch (err) {
            resp.commonErrorResponse('submitContactUs')
        }
    }

    const userAddress = async (formData) => {
        const from = 'userAddress'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'user')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const sendEmailVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendEmailVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendEmailVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendEmailVerifyCode')
        }
    }

    const verifyEmailVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyEmailVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyEmailVerifyCode')
            if (res.data.status === 'success' && res.data.data.token) {
                if (global.session) {
                    sessionStorage.setItem('token', res.data.data.token)
                } else {
                    localStorage.setItem('token', res.data.data.token)
                }
                // loadUser()
            }
        } catch (err) {
            resp.commonErrorResponse('verifyEmailVerifyCode')
        }
    }

    const getInviteDetails = async (formData) => {
        const from = 'getInviteDetails'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData)])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_INVITE_DATA,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addCompanyOwner = async (formData, noAlert, from) => {
        from = from ? from : 'addCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addCompanyOwner', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateCompanyOwner = async (formData, noAlert, from) => {
        from = from ? from : 'updateCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateCompanyOwner', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const removeCompanyOwner = async (formData, noAlert, from) => {
        from = from ? from : 'removeCompanyOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeCompanyOwner', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getCompanyOwners = async (formData, noAlert, from) => {
        from = from ? from : 'getCompanyOwners'
        try {
            const [res] = await Promise.all([apiCall('post', 'getCompanyOwners', formData)])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_COMPANY_OWNER_DATA,
                    payload: {
                        record: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : {},
                    },
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_add')
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_add')
        }
    }

    const getSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('get', 'getSkyflowCredit', formData, '', 'user'),
            ])
            console.log(res)
            if (res.data.status === 'success') {
                console.log('Heree')
                dispatch({
                    type: GET_SKYFLOW_CARDS,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            dispatch({
                type: GET_SKYFLOW_CARDS,
                payload: 'Unable to fetch cards',
            })
        }
    }

    const deleteSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_delete')
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_delete')
        }
    }

    const updateSkyflowCredit = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateSkyflowCredit', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'skyflow_update')
        } catch (err) {
            resp.commonErrorResponse(from, 'skyflow_update')
        }
    }

    const runKycCheck = async (formData, noAlert, from) => {
        from = from ? from : 'runKycCheck'
        try {
            const [res] = await Promise.all([apiCall('post', 'runKycCheck', formData, '', 'user')])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateUserAccountVerified = async (formData, noAlert, from) => {
        from = from ? from : 'updateUserAccountVerified'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateUserAccountVerified', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <UserContext.Provider
            value={{
                responseStatus: state.responseStatus,
                static_page: state.static_page,
                invite_data: state.invite_data,
                company_owner_data: state.company_owner_data,
                skyflow_cards: state.skyflow_cards,
                clearResponse,
                sendPhoneVerifyCode,
                verifyPhoneVerifyCode,
                updateProfile,
                updatePreference,
                forgotPassword,
                resetPassword,
                sendEmailVerifyLink,
                verifyEmail,
                verifyAddress,
                getStaticPage,
                submitContactUs,
                userAddress,
                sendEmailVerifyCode,
                verifyEmailVerifyCode,
                getInviteDetails,
                addCompanyOwner,
                getCompanyOwners,
                updateCompanyOwner,
                removeCompanyOwner,
                addSkyflowCredit,
                getSkyflowCredit,
                deleteSkyflowCredit,
                updateSkyflowCredit,
                runKycCheck,
                updateUserAccountVerified,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
