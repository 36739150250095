import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import './Search.css'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import { Button, IconButton, Fade } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import CommonContext from '../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { messageHandler } from '../../product/common/socketHandler'
import { useCustomMediaQuery, handleRedirectInternal } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import Loaders from '../../custom/components/molecules/Loaders'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const Search = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const { setSearchValue, searchValue, clearSearchValue } = commonContext
    const [loading, setLoading] = useState(false)
    const [savedSearchOn, setSavedSearchOn] = useState(false)
    const [savedSearchTemp, setSavedSearchTemp] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const classes = useStyles()

    const { search_allproducts, getAllSearchProducts, responseStatus, addSavedSearch } =
        productContext
    const { user, isAuthenticated, saveSearch } = authContext

    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('list')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 Per Page',
        },
        {
            value: '24',
            show: 'Results 24 Per Page',
        },
        {
            value: '36',
            show: 'Results 36 Per Page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: 'p.wprice, asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice, desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time Remaining',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
    ]

    // Used to set initail search values
    const [search, setSearch] = useState({
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value:
                    searchValue && searchValue.filters && searchValue.filters.searchbar
                        ? searchValue.filters.searchbar.value
                        : '',
                type: 'like',
                field: 'p.title', //p.desc_proc',
            },
            subCategory: {
                value: [],
                type: 'array',
                field: 'it.subCategoryTypeId',
            },
            third_sub_category: {
                value: [],
                type: 'array',
                field: 'it.third_sub_categoryID',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            shipType: {
                value: [],
                type: 'findinarray',
                field: 'it.shipTypeId',
            },
            price: {
                value: 0,
                type: 'greaterequal',
                field: 'p.wprice',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
        },
    })

    useEffect(() => {
        if (
            searchValue?.filters?.searchbar?.value != null ||
            searchValue?.filters?.searchbar?.value != undefined
        ) {
            setSavedSearchOn(true)
            setSavedSearchTemp(searchValue.filters.searchbar.value)
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    searchbar: {
                        ...search.filters.searchbar,
                        value: searchValue.filters.searchbar.value,
                    },
                },
            })
            getAllSearchProducts(search)
        }

        // setViewProduct([])
        // return () => {
        //     setViewProduct([])
        // }
    }, [])

    useEffect(() => {
        if (searchValue) {
            if (
                searchValue.filters.category &&
                searchValue.filters.category.value &&
                searchValue.filters.category.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        category: {
                            ...search.filters.category,
                            value: searchValue.filters.category.value,
                        },
                    },
                })
            } else if (searchValue.filters.searchbar) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        searchbar: {
                            ...search.filters.searchbar,
                            value: searchValue.filters.searchbar.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.subCategory &&
                searchValue.filters.subCategory.value &&
                searchValue.filters.subCategory.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        subCategory: {
                            ...search.filters.subCategory,
                            value: searchValue.filters.subCategory.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.third_sub_category &&
                searchValue.filters.third_sub_category.value &&
                searchValue.filters.third_sub_category.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        third_sub_category: {
                            ...search.filters.third_sub_category,
                            value: searchValue.filters.third_sub_category.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.shipType &&
                searchValue.filters.shipType.value &&
                searchValue.filters.shipType.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        condition: {
                            ...search.filters.shipType,
                            value: searchValue.filters.shipType.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.location &&
                searchValue.filters.location.value &&
                searchValue.filters.location.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        location: {
                            ...search.filters.location,
                            value: searchValue.filters.location.value,
                        },
                    },
                })
            } else if (searchValue.filters.price && searchValue.filters.price.value) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        price: {
                            ...search.filters.price,
                            value: searchValue.filters.price.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.auctionid &&
                searchValue.filters.auctionid.value &&
                searchValue.filters.auctionid.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        auctionid: {
                            ...search.filters.auctionid,
                            value: searchValue.filters.auctionid.value,
                        },
                    },
                })
            }

            // clearSearchValue()
        }
    }, [searchValue])

    useEffect(() => {
        setIsLoading(true)
        search.filters.searchbar.value = searchValue?.filters?.searchbar?.value || ''
        setSavedSearchTemp(search.filters.searchbar.value)
        if (search.filters.searchbar.value === '') {
            getAllSearchProducts(search)
        } else {
            if (!search.filters.searchbar.value || search.filters.searchbar.value == '')
                if (!search.filters.searchbar.value) {
                    search.filters.searchbar.value = savedSearchTemp
                }
            getAllSearchProducts(search)
        }
    }, [search])

    // Used to change page
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setIsLoading(false)
        setViewProduct(search_allproducts.records.length > 0 ? search_allproducts.records : [])
    }, [search_allproducts])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    // Used to display items in wishlist on page load
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    // Used to saved search message
    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'saved_search_msg' && responseStatus.data) {
    //             setAlert(
    //                 responseStatus.data.message,
    //                 responseStatus.data.status == 'added' ? 'success' : 'error',
    //             )
    //         }
    //     }
    // }, [responseStatus])

    // Used to set and handle sockets
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        setSearch({
            ...search,
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    ...search.filters.category,
                    value: [],
                },
                subCategory: {
                    ...search.filters.subCategory,
                    value: [],
                },
                third_sub_category: {
                    ...search.filters.third_sub_category,
                    value: [],
                },
                shipType: {
                    ...search.filters.condition,
                    value: [],
                },
                location: {
                    ...search.filters.location,
                    value: [],
                },
                price: {
                    ...search.filters.price,
                    value: 0,
                },
                searchbar: {
                    ...search.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...search.filters.auctionid,
                    value: [],
                },
            },
        })
    }

    // useEffect(() => {
    //     if (props.location.state) {
    //         setSearch({
    //             ...search,
    //             filters: {
    //                 ...search.filters,
    //                 searchbar: {
    //                     ...search.filters.searchbar,
    //                     value: props.location.state,
    //                 },
    //             },
    //         })
    //     }
    // }, [props.location.state])
    const addSavedSearcData = (search_text) => {
        addSavedSearch({ search_text })
    }

    const productView = (data) => {
        handleRedirectInternal(history, 'productView')
    }

    return (
        <div className="search">
            <div className="customContainer">
                <div className="srchInrCntnr">
                    <div className="w-100 searchHead d-flex justify-content-between align-items-center flex-wrap">
                        {isAuthenticated && (search?.filters?.searchbar?.value || savedSearchOn) && (
                            <>
                                <h4>
                                    {'You have searched for'}{' '}
                                    <span>
                                        &quot;{search.filters.searchbar.value || savedSearchTemp}
                                        &quot;
                                    </span>
                                </h4>
                                <Button
                                    onClick={() =>
                                        addSavedSearcData(
                                            search.filters.searchbar.value || savedSearchTemp,
                                        )
                                    }
                                >
                                    <span className="material-icons">bookmark_add</span>
                                    {/* REPLACE THE FOLLOWING ICON THE ONCE SEARCH HAS BEEN SAVED */}
                                    {/* <span className="material-icons">bookmark_added</span> */}
                                    {'Save This Search'}
                                </Button>
                            </>
                        )}
                        <Button
                            className="toggleRespDrawer"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                            hidden
                        >
                            <span className="material-icons">tune</span>
                            {'Filters'}
                        </Button>
                    </div>
                    <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                        {/* <div className="gridListToggle">
                        <Button
                            className={auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => setAuctionView('Grid')}
                        >
                            <span className="material-icons">apps</span>
                        </Button>
                        <Button
                            className={auctionView === 'List' ? 'active' : ''}
                            onClick={() => setAuctionView('List')}
                        >
                            <span className="material-icons">view_list</span>
                        </Button>
                    </div> */}
                        <CustomSelect
                            label={'Results Per Page'}
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={search.limit}
                            size="small"
                            onChange={(event, editor) => {
                                setSearch({ ...search, page: 1, limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label={'Sort By'}
                            value={search.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setSearch({ ...search, orderby: event.target.value })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                    <div className="searchCnt d-flex justify-content-start align-items-start">
                        {/* <div className="searchLt">
                    {useCustomMediaQuery('(min-width: 1024px)') ? (
                        <div className="deskFilter">
                            <FilterPanel
                                setSearch={setSearch}
                                clearSearch={clearSearchFilter}
                                search={search}
                                from={1}
                            />
                        </div>
                    ) : (
                        <Drawer
                            anchor="bottom"
                            open={state['bottom']}
                            className="responsiveFilterDrawer"
                            onClose={toggleDrawer('bottom', false)}
                            onOpen={toggleDrawer('bottom', true)}
                        >
                            <div
                                className={clsx(classes.list, 'bottom')}
                                role="presentation"
                                // onClick={toggleDrawer('bottom', false)}
                                onKeyDown={toggleDrawer('bottom', false)}
                            >
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="sbTitle">{'Sort By'}</h4>
                                        <IconButton
                                            onClick={toggleDrawer('bottom', false)}
                                            aria-label="close"
                                            className="pt-0"
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </div>

                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {sortShow.map((opt, optindex) => (
                                                <Button
                                                    key={optindex}
                                                    className={
                                                        search.orderby === opt.value
                                                            ? 'active'
                                                            : null
                                                    }
                                                    onClick={() => {
                                                        setSearch({
                                                            ...search,
                                                            orderby: opt.value,
                                                        })
                                                    }}
                                                >
                                                    {opt.show}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                    <h4 className="sbTitle">{'Results Per Page'}</h4>
                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {pageOptions.map((opt, optindex) => (
                                                <>
                                                    <Button
                                                        className={
                                                            search.limit == opt.value
                                                                ? 'active'
                                                                : null
                                                        }
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                limit: opt.value,
                                                            })
                                                        }}
                                                    >
                                                        {opt.show}
                                                    </Button>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <FilterPanel
                                    setSearch={setSearch}
                                    clearSearch={clearSearchFilter}
                                    search={search}
                                    from={1}
                                />
                            </div>
                        </Drawer>
                    )}
                </div> */}
                        <div className="searchRt">
                            <div className="d-flex justify-content-between align-items-center"></div>
                            {isLoading ? (
                                <Loaders name="product_grid_view" isLoading={true} loop={6} />
                            ) : viewProduct.length > 0 ? (
                                <>
                                    <div className={`searchResults ${auctionView}`}>
                                        <Drawer
                                            className="rightDrawer"
                                            anchor={'right'}
                                            open={state['right']}
                                            onClose={toggleDrawer('right', false)}
                                        >
                                            <ProductView
                                                data={state.data}
                                                onClick={() => setState(false)}
                                            />
                                        </Drawer>

                                        {viewProduct.map((data, index) => (
                                            <>
                                                {auctionView === 'Grid' ? (
                                                    <>
                                                        <GridView
                                                            data={data}
                                                            favId={`searchProd_${index}`}
                                                            drawerHandler={toggleDrawer(
                                                                'right',
                                                                true,
                                                                data,
                                                            )}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ListView
                                                            data={data}
                                                            favId={`searchProd_${index}`}
                                                            drawerHandler={toggleDrawer(
                                                                'right',
                                                                true,
                                                                data,
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                                        <h6>
                                            {'Showing'}&nbsp; {search_allproducts.setDisp} {' of '}
                                            &nbsp; &nbsp;
                                            {search_allproducts.totalRecords}
                                        </h6>
                                        <Pagination
                                            count={Math.ceil(
                                                search_allproducts.totalRecords / search.limit,
                                            )}
                                            page={search.page}
                                            onChange={onHandlePage}
                                            siblingCount={3}
                                            showFirstButton
                                            showLastButton
                                            boundaryCount={2}
                                        />
                                    </div>
                                </>
                            ) : (
                                <NoRecordsFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* PRODUCT VIEW DRAWER */}
        </div>
    )
}
export default Search
