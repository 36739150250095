import React, { useReducer } from 'react'
import ReportContext from './reportContext'
import ReportReducer from './reportReducer'
import { apiCallSeller } from '../../../common/api'
import { response } from '../../common'

import { GET_ALL_REPORT_DISPLAY, CLEAR_RESPONSE, RESPONSE_STATUS } from './reportTypes'
const FileSaver = require('file-saver')

const ReportState = (props) => {
    const initialState = {
        responseStatus: null,
        reports_data: null,
    }

    const [state, dispatch] = useReducer(ReportReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllReportDisplay = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'display', formData, '', 'report'),
            ])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_REPORT_DISPLAY,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReportDownload = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'download', formData, '', 'report'),
            ])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                const csvData = new Blob([res.data.data.responseData.records], {
                    type: 'text/csv;charset=utf-8;',
                })
                FileSaver.saveAs(csvData, res.data.data.responseData.filename)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllExportDownload = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'export', formData, '', 'report'),
            ])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                const csvData = new Blob([res.data.data.responseData.records], {
                    type: 'text/csv;charset=utf-8;',
                })
                FileSaver.saveAs(csvData, res.data.data.responseData.filename)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <ReportContext.Provider
            value={{
                reports_data: state.reports_data,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllReportDisplay,
                getAllReportDownload,
                getAllExportDownload,
            }}
        >
            {props.children}
        </ReportContext.Provider>
    )
}

export default ReportState
