import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '@/custom/components/organisms/ProductView'
import GridView from '@/custom/components/molecules/ProductCard/GridView'
import ListView from '@/custom/components/molecules/ProductCard/ListView'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import Loaders from '@/custom/components/molecules/Loaders'
import openSocket from 'socket.io-client'
import { Pagination } from '@material-ui/lab'
import { messageHandler } from '@/product/common/socketHandler'
import NoRecordsFound from '@/product/components/atoms/NoRecordsFound'

const ProductViewComponent = (props) => {
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])
    const { formikSearch } = props
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    let history = useHistory()

    // const classes = useStyles()
    const { user, isAuthenticated, responseStatus, clearResponse } = authContext
    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: responseStatusProduct,
    } = productContext

    const { setAlert } = alertContext
    const [isLoading, setIsLoading] = useState(true)
    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
        setIsLoading(false)
    }, [viewProduct])

    useEffect(() => {
        if (user && isAuthenticated) {
            getAllDashboardProducts(formikSearch.values)
        }
    }, [formikSearch.values, user, isAuthenticated])

    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        //console.log('search_alldashboardproducts', search_alldashboardproducts)
        setViewProduct(
            search_alldashboardproducts.records.length ? search_alldashboardproducts.records : [],
        )
    }, [search_alldashboardproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (responseStatusProduct) {
            if (
                responseStatusProduct.from === 'addWatchlist' ||
                responseStatusProduct.from === 'removeWatchlist'
            ) {
                if (formikSearch.values.action === 'watchlist') {
                    if (responseStatusProduct.status === 'success') {
                        getAllDashboardProducts(formikSearch.values)
                    }
                }
            }
        }
    }, [responseStatusProduct])

    return (
        <>
            {isLoading ? (
                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
            ) : viewProduct.length ? (
                <>
                    <div className={`searchResults ${formikSearch.values.auctionView}`}>
                        <Drawer
                            className="rightDrawer"
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                        >
                            <ProductView
                                data={state.data}
                                drawerHandler={toggleDrawer('right', false)}
                            />
                        </Drawer>
                        {viewProduct.map((data, index) => (
                            <>
                                {formikSearch.values.auctionView === 'Grid' ? (
                                    <>
                                        <GridView
                                            data={data}
                                            from="dashboard"
                                            action={props.type}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </>
                                ) : (
                                    <ListView
                                        data={data}
                                        from="dashboard"
                                        action={props.type}
                                        favId={`searchProd_${index}`}
                                        drawerHandler={toggleDrawer('right', true, data)}
                                        viewProduct={viewProduct}
                                    />
                                )}
                            </>
                        ))}
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                        <h6>
                            {'Showing'}&nbsp; {search_alldashboardproducts.setDisp} &nbsp;{' of '}
                            &nbsp;
                            {search_alldashboardproducts.totalRecords}
                        </h6>
                        <Pagination
                            count={Math.ceil(
                                search_alldashboardproducts.totalRecords /
                                    formikSearch.values.limit,
                            )}
                            page={formikSearch.values.page}
                            onChange={onHandlePage}
                            siblingCount={3}
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                        />
                    </div>
                </>
            ) : (
                <NoRecordsFound />
            )}
        </>
    )
}

export default ProductViewComponent
