import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { mapData } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
import UserContext from '@/product/context/user/userContext'
import CommonContext from '@/custom/context/common/commonContext'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import PhoneValidationComponent from '@/product/components/species/components/user/PhoneValidationComponent'
import AddressViewComponent from '@/product/components/species/components/user/profile/AddressViewComponent'

import { Button } from '@material-ui/core'
const ProfileComponent = (props) => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)

    const history = useHistory()

    const { updateProfile, updatePreference, responseStatus } = userContext
    const { allNotifications, configFeatures } = commonContext
    const { user, loadUser } = authContext

    const [phoneVerify, setPhoneVerify] = useState(false)
    const [isSecondaryBtn, setSecondaryBtn] = useState(false)

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const [currentCountry, setCurrentCountry] = useState()

    const submitHandler = (payload) => {
        if (isSecondaryBtn) {
            updateProfile(payload, props.noAlert, 'saveExit')
        } else {
            updateProfile(payload, props.noAlert)
        }
    }

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (props.phoneVerify) {
                if (formik.values.phone) {
                    if (user.phone === formik.values.phone) {
                        delete values.email
                        submitHandler(values)
                    } else if (
                        values.phone_verified === 1 &&
                        formik.values.verified_phonenum === formik.values.phone
                    ) {
                        delete values.email
                        submitHandler(values)
                    } else {
                        setPhoneVerify(true)
                    }
                } else {
                    delete values.email
                    submitHandler(values)
                }
            } else {
                delete values.email
                submitHandler(values)
            }
        },
    })

    const profileform = props.formFields ? { formik: formik, ...props.formFields } : {}

    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })

    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.email === 1),
            formik: formikPreference,
        },
    ]

    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.sms === 1),
            formik: formikPreference,
        },
    ]

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateProfile') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    }
                }
            } else if (responseStatus.from === 'saveExit') {
                if (responseStatus.status === 'success') {
                    if (props.onFormSaveExit) {
                        props.onFormSaveExit()
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
            <div className="accChild">
                {props.showHeadText ? (
                    <> {props.headText ? <h4>{props.headText}</h4> : <h4>Profile </h4>} </>
                ) : null}
                <div className="row">{mapData(profileform)}</div>
            </div>
            {props.customBtnGroup ? (
                <div className="customBtnGroup">{props.customBtnGroup}</div>
            ) : (
                <>
                    <div className="profileAct">
                        {props.onFormBack ? (
                            <SecondaryButton
                                id="prev"
                                onClick={props.onFormBack ? props.onFormBack : null}
                                disabled={props.backBtnDisabled ? props.backBtnDisabled : null}
                                label={props.BackBtnLabel ? props.BackBtnLabel : 'Prev'}
                            />
                        ) : (
                            ''
                        )}
                        {props.SaveExitBtnLabel ? (
                            <SecondaryButton
                                id="save_and_exit"
                                className="btnSkip"
                                onClick={() => {
                                    formik.handleSubmit()
                                    setSecondaryBtn(true)
                                }}
                                label={
                                    props.SaveExitBtnLabel ? props.SaveExitBtnLabel : 'Save & Exit'
                                }
                            />
                        ) : (
                            ''
                        )}
                        <PrimaryButton
                            id="profile_saveBtn"
                            onClick={() => {
                                formik.handleSubmit()
                                setSecondaryBtn(false)
                            }}
                            disabled={props.successBtnDisabled ? props.successBtnDisabled : false}
                            label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Save'}
                        />
                    </div>
                    {props.onFormSkip ? (
                        <Button id="skip" className="btnSkip" onClick={props.onFormSkip}>
                            {props.SkipBtnLabel ? props.SkipBtnLabel : 'Skip'}
                        </Button>
                    ) : (
                        ''
                    )}
                </>
            )}

            {props.addressDetails ? (
                <AddressViewComponent validateAddress={props.validateAddress || false} />
            ) : null}
            {props.phoneVerify ? (
                <PhoneValidationComponent
                    phoneVerify={phoneVerify}
                    formik={formik}
                    setPhoneVerify={setPhoneVerify}
                    changePhoneVerify={changePhoneVerify}
                />
            ) : null}
        </form>
    )
}

export default ProfileComponent
