import React, { useContext, useState, useEffect } from 'react'
import CustomDialog from '../../../../components/organisms/Dialog'
import PrimaryButton from '../../../atoms/PrimaryButton/index'
import Button from '@material-ui/core/Button'
import UserContext from '../../../../context/user/userContext'
import { handleRedirectInternal, mapData } from '../../../../common/components'
import Loaders from '../../../molecules/Loaders'

export default function PhoneValidationComponent(props) {
    const phoneVerify = props.phoneVerify
    const changePhoneVerify = props.changePhoneVerify
    const formik = props.formik
    const setPhoneVerify = props.setPhoneVerify

    const [disable, setDisable] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const userContext = useContext(UserContext)

    const {
        sendPhoneVerifyCode,
        verifyPhoneVerifyCode,
        responseStatus: responseStatusUser,
    } = userContext

    const sendVerifyCode = () => {
        setIsloading(true)
        sendPhoneVerifyCode({
            phone: formik.values.phone,
            isRegister: formik.values.isRegister,
            isForgotPassword: formik.values.isForgotPassword,
        })
    }

    const verifyPhoneCode = () => {
        setIsloading(true)
        verifyPhoneVerifyCode({
            phone: formik.values.phone,
            verify_code: formik.values?.verify_code,
        })
    }

    const verifyPhone = [
        {
            label: 'Verification Code',
            placeholder: 'Enter Verification Code',
            class: 'col-12',
            type: 'otpInput',
            name: 'verify_code',
            formik: formik,
            separator: ' ',
            placeholder: '000000',
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'sendPhoneVerifyCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('phone_verifysent', 1)
                }
                if (responseStatusUser.from === 'verifyPhoneVerifyCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('phone_verified', 1)
                    formik.setFieldValue('verified_phonenum', formik.values.phone)
                    if (props.verifyStatusChange) {
                        formik.setFieldValue('status', 'active')
                    }
                    setPhoneVerify(false)
                }
            } else {
                setTimeout(() => {
                    setIsloading(false)
                }, 500)
            }
        }
        setDisable(false)
    }, [responseStatusUser])

    useEffect(() => {
        if (formik.values.phone_verifysent) {
            formik.setFieldValue('phone_verifysent', 0)
            formik.setFieldValue('phone_verified', 0)
            formik.setFieldValue('status', 'email_unverified')
        }
    }, [formik.values.phone])

    return (
        <>
            <CustomDialog
                className="verifyPhone"
                // title={'Verify Phone'}
                open={phoneVerify}
                handleClose={() => changePhoneVerify()}
                // function={changePhoneVerify}
            >
                {isLoading ? (
                    <Loaders name="home" isLoading={isLoading} />
                ) : (
                    <>
                        {formik.values.phone_verifysent === 1 ? (
                            <>
                                <h4 className="vpTitle">
                                    We&apos;ve sent a confirmation code to {formik.values.phone}.
                                </h4>
                                <br />
                            </>
                        ) : (
                            <h4 className="vpbTitle">
                                A confirmation code will be sent to{' '}
                                <span>{formik.values.phone}</span>. <br /> Please confirm if your
                                number is correct.
                            </h4>
                        )}

                        {formik.values.phone_verifysent === 1 && (
                            <>
                                <div className="row phVerInp">
                                    {Object.values(mapData(verifyPhone))}
                                </div>
                                <span className="phVer">
                                    * {'Your code will expire in 5 minutes.'}
                                </span>
                                <PrimaryButton
                                    id="phoneCode"
                                    onClick={verifyPhoneCode}
                                    label={'Verify Code'}
                                />
                            </>
                        )}
                        <div className="actionWrapper secondaryActions">
                            {/* <Button onClick={() => changePhoneVerify()}>{'Cancel'}</Button> */}
                            {formik.values.phone_verifysent === 1 ? (
                                <Button
                                    id="resend_sms"
                                    disabled={disable}
                                    className="resendSms"
                                    onClick={() => {
                                        setDisable(true)
                                        sendVerifyCode()
                                    }}
                                >
                                    {'Resend SMS'}
                                </Button>
                            ) : (
                                <PrimaryButton
                                    id="verifyCode"
                                    label={'Send SMS'}
                                    onClick={() => sendVerifyCode()}
                                />
                            )}
                        </div>
                    </>
                )}
            </CustomDialog>
        </>
    )
}
