import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import CommonContext from '@/custom/context/common/commonContext'
import UserContext from '@/product/context/user/userContext'
import AuthContext from '@/product/context/auth/authContext'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CheckBox from '@/product/components/atoms/CheckBox'
import NoRecordsFound from '@/product/components/atoms/NoRecordsFound'

const NotificationComponent = (props) => {
    const commonContext = useContext(CommonContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)

    const { allNotifications } = commonContext
    const { updatePreference, responseStatus: responseStatusUser } = userContext
    const { user, loadUser } = authContext
    const [reload, setReload] = useState(false)

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: (values) => {
            updatePreference(values)
        },
    })

    useEffect(() => {
        if (user) {
            formik.values.email_prefer =
                user.email_notification && user.email_notification.split(',').length
                    ? user.email_notification.split(',')
                    : []
            formik.values.sms_prefer =
                user.sms_notification && user.sms_notification.split(',').length
                    ? user.sms_notification.split(',')
                    : []
            setReload(!reload)
        }
    }, [user])

    // useEffect(() => {
    //     if (responseStatusUser) {
    //         if (responseStatusUser.from === 'updatePreference') {
    //             if (responseStatusUser.status === 'success') {
    //                 loadUser()
    //             }
    //         }
    //     }
    // }, [responseStatusUser])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <div className="table-responsive dpTable">
            {props.initialValues && allNotifications.length ? (
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <table className="table text-left">
                        <thead>
                            <tr>
                                <th scope="col">Notification</th>
                                {Object.keys(props.initialValues).includes('email_prefer') ? (
                                    <th scope="col">Email</th>
                                ) : (
                                    <></>
                                )}
                                {Object.keys(props.initialValues).includes('sms_prefer') ? (
                                    <th scope="col">SMS</th>
                                ) : (
                                    <></>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {allNotifications.map((data, index) => (
                                <>
                                    <tr key={data.id}>
                                        <td>{data.description}</td>
                                        {Object.keys(props.initialValues).includes(
                                            'email_prefer',
                                        ) ? (
                                            <td hidden={data.email == 0 ? true : false}>
                                                <div hidden={data.email == 0 ? true : false}>
                                                    <CheckBox
                                                        name={'email_prefer'}
                                                        label={''}
                                                        checked={
                                                            formik.values['email_prefer']?.indexOf(
                                                                data.id.toString(),
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        {Object.keys(props.initialValues).includes('sms_prefer') ? (
                                            <td>
                                                <div hidden={data.sms == 0 ? true : false}>
                                                    <CheckBox
                                                        name={'sms_prefer'}
                                                        label={''}
                                                        checked={
                                                            formik.values['sms_prefer']?.indexOf(
                                                                data.id.toString(),
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                    <PrimaryButton id="notify_save" type="submit" label={'Save'} />
                </form>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default NotificationComponent
