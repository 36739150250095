import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    COMMON_VALUES,
    BID_HISTORY_VALUE,
    US_STATE_VALUE,
    UPLOADED_IMAGE,
    CLEAR_SEARCH,
    GET_STATIC_PAGE,
    LOADERS,
    CLEAR_BID_HISTORY,
    TTWBID_HISTORY_VALUE,
    CLEAR_TTWBID_HISTORY,
    SEARCH_VALUE,
    CURRENCY_VALUE,
    GET_PREVIOUS_DATA,
    GET_ALL_COUNTRIES,
    GET_ALL_STATES,
    GET_ALL_CITIES,
    GET_FOOTER_LINK,
    GET_ALL_DYNAMIC_ARRAY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        responseStatus: null,
        allCategory: [],
        allLocations: [],
        allCondition: [],
        allDamageTypes: [],
        allPackageTypes: [],
        allBidIncrements: [],
        allBuyerPremium: [],
        allNotifications: [],
        allCountries: [],
        allStates: [],
        dynamicValue: [],
        allCities: [],
        searchValue: null,
        currencyValue: localStorage.currency ? JSON.parse(localStorage.currency) : null,
        bidHistoryValue: 0,
        ttwBidHistoryValue: 0,
        configVariables: {},
        configFeatures: {},
        USStates: [],
        uploadedFiles: [],
        static_page: {
            record: {},
        },
        previous_data: {
            records: [],
            totalRecords: 0,
        },
        isLoading: true,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])

        if (res?.data?.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes,
                    allDamageTypes: res.data.data.responseData.tableValues.alldamageTypes,
                    allPackageTypes: res.data.data.responseData.tableValues.allpackageTypes,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    allBuyerPremium: res.data.data.responseData.allBuyerPremium,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configVariables: res.data.data.responseData.variables,
                    configFeatures: res.data.data.responseData.features,
                },
            })
        }
    }

    const getAllCountries = async (formData) => {
        const from = 'getAllCountries'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', 'commonFunction'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_COUNTRIES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllStates = async (formData) => {
        const from = 'getAllStates'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', 'commonFunction'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_STATES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllCities = async (formData) => {
        const from = 'getAllCities'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', 'commonFunction'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CITIES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllDynamicArray = async (formData, fromVariable) => {
        const from = 'getAllDynamicArray'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'commonFunction')])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DYNAMIC_ARRAY,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getStaticPage = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getStaticPage', formData, '', 'common'),
            ])
            const from = 'StaticPage'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getFooterLinks = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getFooterLinks', formData, '', 'common'),
            ])
            const from = 'footerLinks'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_FOOTER_LINK,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const insertEntry = async (formData) => {
        const from = 'insertEntry'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'common')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateEntry = async (formData) => {
        const from = 'updateEntry'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'common')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getPreviousData = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPreviousData', formData, '', 'common'),
            ])
            dispatch({
                type: GET_PREVIOUS_DATA,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    from: fromVariable,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('getPreviousData')
        }
    }

    const clearPreviousData = async () => {
        dispatch({
            type: GET_PREVIOUS_DATA,
            payload: {
                records: [],
                totalRecords: 0,
            },
        })
    }

    const setUSState = async (data) => {
        dispatch({
            type: US_STATE_VALUE,
            payload: data,
        })
    }

    const setSearchValue = async (data) => {
        dispatch({
            type: SEARCH_VALUE,
            payload: data,
        })
    }

    const setCurrencyValue = async (data) => {
        dispatch({
            type: CURRENCY_VALUE,
            payload: data,
        })
    }

    const setBidHistoryValue = async (data) => {
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const setTTWBidHistoryValue = async (data) => {
        dispatch({
            type: TTWBID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearTTWBidHistoryValue = () =>
        dispatch({
            type: CLEAR_TTWBID_HISTORY,
        })

    const clearSearchValue = () =>
        dispatch({
            type: CLEAR_SEARCH,
        })

    const uploadImage = async (formData, fromUpload) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'muliple_img_uploader',
                    formData,
                    '',
                    'commonFunction/image_uploader',
                ),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: UPLOADED_IMAGE,
                    payload: {
                        files: res.data.data.responseData.files,
                        from: fromUpload,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const resetDropZone = (fromUpload) => {
        dispatch({
            type: UPLOADED_IMAGE,
            payload: {
                files: [],
                from: fromUpload,
            },
        })
    }

    const loaderSet = (isLoad) => {
        dispatch({
            type: LOADERS,
            payload: isLoad,
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allDamageTypes: state.allDamageTypes,
                allPackageTypes: state.allPackageTypes,
                allNotifications: state.allNotifications,
                allBidIncrements: state.allBidIncrements,
                allBuyerPremium: state.allBuyerPremium,
                searchValue: state.searchValue,
                currencyValue: state.currencyValue,
                static_page: state.static_page,
                footer_links: state.footer_links,
                bidHistoryValue: state.bidHistoryValue,
                configVariables: state.configVariables,
                configFeatures: state.configFeatures,
                uploadedFiles: state.uploadedFiles,
                responseStatus: state.responseStatus,
                dynamicValue: state.dynamicValue,
                USStates: state.USStates,
                isLoading: state.isLoading,
                ttwBidHistoryValue: state.ttwBidHistoryValue,
                previous_data: state.previous_data,
                allCountries: state.allCountries,
                allStates: state.allStates,
                allCities: state.allCities,
                insertEntry,
                updateEntry,
                setTTWBidHistoryValue,
                clearTTWBidHistoryValue,
                uploadImage,
                getGlobalVariable,
                setSearchValue,
                setCurrencyValue,
                clearSearchValue,
                setBidHistoryValue,
                loaderSet,
                clearBidHistoryValue,
                getAllDynamicArray,
                getStaticPage,
                getFooterLinks,
                getPreviousData,
                clearPreviousData,
                getAllCountries,
                getAllStates,
                getAllCities,
                setUSState,
                clearResponse,
                resetDropZone,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
