import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductView.css'
import { useHistory } from 'react-router-dom'
import Loaders from '../../custom/components/molecules/Loaders'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import {
    dateFormatFront,
    dateTimeFormatFunction,
    asyncForEach,
} from '../../product/common/components'
import Timer from '../../product/common/timer'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import AuctionContext from '../../product/context/auction/auctionContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { messageHandler } from '../../product/common/socketHandler'
import AlertContext from '../../product/context/alert/alertContext'
import AuthContext from '../../product/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Pagination } from '@material-ui/lab'
import ReverseBidding from '../../custom/components/molecules/Reverse/Bidding/BiddingItem'
import PreBidding from '../../custom/components/molecules/Reverse/PreBidding/PreBiddingItem'
import BidHistoryComp from '../../custom/components/organisms/BidHistory/BidHistoryComp'
import { currencyFormat } from '../../custom/common/components'
import BidHistory from '../../custom/components/organisms/BidHistory'

function LiveAuction(props) {
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const auctionContext = useContext(AuctionContext)
    const { allLocations } = customCommonContext
    const {
        search_allauctions,
        getAllAuctionProducts,
        addWatchlistauction,
        removeWatchlistauction,
        responseStatus,
    } = auctionContext

    const { getAllSearchProducts, search_allproducts } = useContext(ProductContext)
    const [auctionProducts, setAuctionProducts] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({})
    const [isLoadingAuction, setIsLoadingAuction] = useState(true)
    const [isLoadingProject, setIsLoadingProject] = useState(true)
    const [reloadBidHistory, setReloadBidHistory] = useState(true)
    const [productBidChange, setProductBidChange] = useState(0)

    const history = useHistory()
    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext
    const productCommonContext = useContext(ProductCommonContext)
    const { setBidHistoryValue } = productCommonContext
    let project_id = props.match.params.aid
    const [checked, setChecked] = useState(parseInt(props.watchlisted) > 0 ? true : false)

    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            market_status: ['open', 'closed', 'sold', 'unsold'],
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                // location: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.location_id',
                // },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        },
    })
    const toggleFavourite = () => {
        var pro = {
            auction_id: project_id,
        }

        if (checked) {
            removeWatchlistauction(pro)
            setChecked(false)
        } else {
            addWatchlistauction(pro)
            setChecked(true)
        }
    }

    const setLoginModal = () => {
        handleRedirectInternal(history, '/login')
    }

    useEffect(() => {
        setChecked(parseInt(auctionDetails.following_auction) > 0 ? true : false)
        return () => {
            setChecked(false)
        }
    }, [project_id, auctionDetails.following_auction])

    useEffect(() => {
        formikSearch.setFieldValue(`filters.auctionid.value`, [Number(props.match.params.aid)])
        setIsLoadingAuction(true)
        getAllAuctionProducts(
            {
                limit: 1,
                page: 1,
                orderby: 'ac.date_closed, asc',
                order: '',
                market_status: ['open', 'closed'],
                filters: {
                    auctionid: {
                        value: Number(props.match.params.aid),
                        type: 'in',
                        field: 'ac.id',
                    },
                },
            },
            'liveauction',
        )
    }, [props.match.params.aid, isAuthenticated])

    useEffect(() => {
        if (formikSearch.values.filters.auctionid.value.length) {
            setIsLoadingProject(true)
            getAllSearchProducts(formikSearch.values, 'searchproducts')
        }
    }, [formikSearch.values])

    // Used to change page
    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        if (search_allproducts.from === 'searchproducts') {
            setAuctionProducts(search_allproducts.records.length ? search_allproducts.records : [])
            setTimeout(() => {
                setIsLoadingProject(false)
            }, 1500)
        }
    }, [search_allproducts])

    useEffect(() => {
        if (search_allauctions.from === 'liveauction') {
            if (search_allauctions && search_allauctions.records.length > 0) {
                setAuctionDetails(search_allauctions.records[0])
                setTimeout(() => {
                    setIsLoadingAuction(false)
                }, 1500)
            } else {
                // handleRedirectInternal(history, 'Auction')
            }
        }
    }, [search_allauctions])

    const viewProductRef = useRef(auctionProducts)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = auctionProducts
        userRef.current = user
    }, [user, auctionProducts])

    useEffect(() => {
        if (productBidChange) {
            if (productBidChange === auctionProducts[0].id) {
                setReloadBidHistory(!reloadBidHistory)
            }
            setProductBidChange(0)
        }
    }, [productBidChange, auctionProducts])

    const bidHistoryChange = (data) => {
        setProductBidChange(data.product_id)
    }

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setAuctionProducts,
            type,
            bidHistoryChange,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
            transports: ['websocket'],
        })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('preBidAddtime', (data) => {
            handler(data, 'preBidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('preBidAddtime', (data) => {
                handler(data, 'preBidAddtime')
            })
            socket.disconnect()
        }
    }, [])
    useEffect(() => {
        if (auctionProducts[0]) {
            console.log(auctionProducts[0], 'auctionlot')
            console.log(auctionProducts[0].rprice >= auctionProducts[0].current_bid, 'auctionlot')
        }
    }, [auctionProducts[0]])

    // useEffect(() => {
    //     if (auctionDetails?.projects_count == 1) {
    //         console.log('auctionProducts[0]?.id', auctionProducts[0]?.id)
    //         setBidHistoryValue(auctionProducts[0]?.id)
    //     }
    // }, [auctionDetails, auctionProducts])

    return (
        <div className="liveAuction">
            <div className="customContainer">
                <div className="pdtContainer">
                    {auctionDetails?.projects_count > 1 ? (
                        <>
                            {isLoadingAuction ? (
                                <div className="liveAucLoader customContainer">
                                    <Loaders
                                        isLoading={isLoadingAuction}
                                        name="product_view"
                                        loop={1}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="bgBckWrp mb-2">
                                        <div className="actnDtlWrpr">
                                            <div className="acContent">
                                                <h2 className="actnTtrl mb-2">
                                                    {auctionDetails.title}
                                                </h2>
                                                <h6
                                                    dangerouslySetInnerHTML={{
                                                        __html: auctionDetails.description,
                                                    }}
                                                ></h6>
                                            </div>
                                            <div className="lctnChipWrp">
                                                <span className="material-icons">place</span>
                                                {allLocations
                                                    .filter(
                                                        (value) =>
                                                            auctionDetails.location_id === value.id,
                                                    )
                                                    .map((location) => (
                                                        <span key={location.id}>
                                                            {location.name}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {isLoadingProject ? (
                                <div className="liveAucLoader customContainer">
                                    <Loaders
                                        name="product_list_view"
                                        isLoading={isLoadingProject}
                                        loop={1}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="bgBckWrp">
                                        <div className="row">
                                            <div className="searchResults col-md-12 col-12">
                                                {auctionProducts
                                                    ? auctionProducts.map((data, index) => (
                                                          <ListView
                                                              data={data}
                                                              auction={auctionDetails}
                                                              key={index}
                                                              favId={`searchProd_${index}`}
                                                          />
                                                      ))
                                                    : null}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                                                <h6>
                                                    {'Showing'}&nbsp; {search_allproducts.setDisp}{' '}
                                                    &nbsp;{'of'}&nbsp; &nbsp;
                                                    {search_allproducts.totalRecords}
                                                </h6>
                                                <Pagination
                                                    count={Math.ceil(
                                                        search_allproducts.totalRecords /
                                                            formikSearch.values.limit,
                                                    )}
                                                    page={formikSearch.values.page}
                                                    onChange={onHandlePage}
                                                    siblingCount={3}
                                                    showFirstButton
                                                    showLastButton
                                                    boundaryCount={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {isLoadingAuction ? (
                                <div className="liveAucLoader customContainer">
                                    <Loaders
                                        isLoading={isLoadingAuction}
                                        name="product_view"
                                        loop={1}
                                    />
                                </div>
                            ) : (
                                <>
                                    {Object.keys(auctionProducts).length ? (
                                        <div className="singleAuctionView">
                                            <div className="pdtContainer d-flex align-items-start justify-content-between">
                                                <div className="lftPdtContainer text-left bgBckWrp pdtDtlCntnr">
                                                    <div className="listBidStatus">
                                                        {isAuthenticated && (
                                                            <>
                                                                {auctionProducts[0].bidtopstatus ===
                                                                'outbid' ? (
                                                                    <div className="pvBidStatus">
                                                                        <h4 className="outbid">
                                                                            {'Your rank in bidders ' +
                                                                                auctionProducts[0]
                                                                                    .rank}
                                                                        </h4>
                                                                    </div>
                                                                ) : null}
                                                                {auctionProducts[0].bidtopstatus ===
                                                                'winner' ? (
                                                                    auctionProducts[0].rprice >=
                                                                    auctionProducts[0]
                                                                        .current_bid ? (
                                                                        <div className="pvBidStatus">
                                                                            <h4 className="winning">
                                                                                {'Your rank in bidders ' +
                                                                                    auctionProducts[0]
                                                                                        .rank}
                                                                            </h4>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="pvBidStatus">
                                                                            <h4 className="winning">
                                                                                {'Your rank in bidders ' +
                                                                                    auctionProducts[0]
                                                                                        .rank}
                                                                                <span>
                                                                                    . But Reserve
                                                                                    price not met!
                                                                                </span>
                                                                            </h4>
                                                                        </div>
                                                                    )
                                                                ) : null}

                                                                {auctionProducts[0].bidtopstatus ===
                                                                'won' ? (
                                                                    <div className="pvBidStatus">
                                                                        <h4 className="won">Won</h4>
                                                                    </div>
                                                                ) : null}
                                                                {auctionProducts[0].bidtopstatus ===
                                                                'lost' ? (
                                                                    <div className="pvBidStatus">
                                                                        <h4 className="lost">
                                                                            Lost
                                                                        </h4>
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </div>
                                                    <h2 className="text-left d-flex align-items-center justify-content-between">
                                                        {auctionDetails.title}
                                                        {isAuthenticated ? (
                                                            <>
                                                                <div>
                                                                    <input
                                                                        id={project_id}
                                                                        type="checkbox"
                                                                        checked={checked}
                                                                        hidden
                                                                        onChange={toggleFavourite}
                                                                    />
                                                                    {checked ? (
                                                                        <label
                                                                            className="adFavLable"
                                                                            htmlFor={project_id}
                                                                        >
                                                                            <span className="material-icons">
                                                                                favorite
                                                                            </span>
                                                                            Added to favorites
                                                                        </label>
                                                                    ) : (
                                                                        <label
                                                                            htmlFor={project_id}
                                                                            className="adFavLable"
                                                                        >
                                                                            <span className="material-icons">
                                                                                favorite_border
                                                                            </span>
                                                                            Add to favorites
                                                                        </label>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <label
                                                                className="adFavLable"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'login',
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    favorite_border
                                                                </span>
                                                                Add to favorites
                                                            </label>
                                                        )}
                                                    </h2>
                                                    <h6
                                                        className="dscpData"
                                                        dangerouslySetInnerHTML={{
                                                            __html: auctionDetails.description,
                                                        }}
                                                    ></h6>
                                                    <div className="grdCntnr">
                                                        <div className="d-flex align-items-center">
                                                            <label className="m-0">
                                                                Started On:
                                                            </label>
                                                            <p className="m-0">
                                                                {dateFormatFront(
                                                                    auctionDetails.date_added,
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <label className="m-0">Ends On:</label>
                                                            <p className="m-0">
                                                                {auctionDetails
                                                                    ? dateFormatFront(
                                                                          auctionDetails.date_closed,
                                                                      )
                                                                    : null}
                                                            </p>
                                                        </div>
                                                        {auctionProducts[0].auction ? (
                                                            <>
                                                                {auctionProducts[0]
                                                                    .market_status === 'open' ? (
                                                                    <>
                                                                        <>
                                                                            {auctionProducts[0]
                                                                                .bidhistory.length >
                                                                            0 ? (
                                                                                <>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <label className="m-0">
                                                                                            Current
                                                                                            Bid:
                                                                                        </label>
                                                                                        <p className="m-0 prcAmount">
                                                                                            {currencyFormat(
                                                                                                auctionProducts[0]
                                                                                                    .wprice,
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <label className="m-0">
                                                                                            Starting
                                                                                            Bid:
                                                                                        </label>
                                                                                        <p className="m-0 prcAmount">
                                                                                            {currencyFormat(
                                                                                                auctionProducts[0]
                                                                                                    .sprice,
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}

                                                        <div className="d-flex align-items-center">
                                                            <label className="m-0">Location:</label>
                                                            <p className="m-0">
                                                                {allLocations
                                                                    .filter(
                                                                        (value) =>
                                                                            auctionDetails.location_id ===
                                                                            value.id,
                                                                    )
                                                                    .map((location) => (
                                                                        // console.log(
                                                                        //     'locations',
                                                                        //     allLocations,
                                                                        // ),
                                                                        <span className="adrsInfoCrd">
                                                                            {location.name}
                                                                        </span>
                                                                    ))}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {auctionProducts[0].market_status === 'open' ? (
                                                        <div
                                                            className={`pvTimerBox d-flex justify-content-center align-items-center flex-wrap ${
                                                                auctionProducts[0]?.future_active ==
                                                                1
                                                                    ? 'upcomingSlot'
                                                                    : auctionProducts[0]
                                                                          ?.future_active == 0
                                                                    ? 'activeSlot'
                                                                    : auctionProducts[0]
                                                                          ?.market_status ==
                                                                      'closed'
                                                                    ? 'closedSlot'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <span className="material-icons">
                                                                schedule
                                                            </span>
                                                            <h4>
                                                                <Timer
                                                                    date_added={
                                                                        auctionProducts[0]
                                                                            .date_added
                                                                    }
                                                                    date_closed={
                                                                        auctionProducts[0]
                                                                            .date_closed
                                                                    }
                                                                    daysText={' Days '}
                                                                    hoursText={' Hours '}
                                                                    minutesText={' Minutes '}
                                                                    secondsText={' Seconds '}
                                                                    withText={0}
                                                                    endText={'Time left' + ':'}
                                                                    startText={'Starts in' + ':'}
                                                                ></Timer>
                                                            </h4>
                                                        </div>
                                                    ) : (
                                                        <div className="pvTimerBox">
                                                            <span className="material-icons">
                                                                schedule
                                                            </span>
                                                            <label>{'Closed On'}</label>
                                                            <h4>
                                                                {dateFormatFront(
                                                                    auctionDetails.date_closed,
                                                                )}
                                                            </h4>
                                                        </div>
                                                    )}
                                                    <div className="bdngContainer">
                                                        <div className="pvBidBox">
                                                            {auctionProducts[0].market_status ===
                                                                'open' &&
                                                            !auctionProducts[0].future_active ? (
                                                                <>
                                                                    {auctionProducts[0].auction ? (
                                                                        <>
                                                                            <ReverseBidding
                                                                                data={
                                                                                    auctionProducts[0]
                                                                                }
                                                                                type={'hard'}
                                                                                accept_rejected={
                                                                                    auctionProducts[0]
                                                                                        .enable_duplicate
                                                                                }
                                                                                accept_non_winning={
                                                                                    auctionProducts[0]
                                                                                        .custom_auctionlot_enable_non_winning
                                                                                }
                                                                            />

                                                                            <p className="bdInfoTxt">
                                                                                Fixed Single Bid -
                                                                                Place your bid on
                                                                                the current price.
                                                                            </p>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : auctionDetails?.prebid_available ? (
                                                                <PreBidding
                                                                    data={auctionProducts[0]}
                                                                    type={'hard'}
                                                                    showMessage={true}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        {auctionDetails.enable_proxy ? (
                                                            <>
                                                                <div className="pvBidBox">
                                                                    {auctionProducts[0]
                                                                        .market_status === 'open' &&
                                                                    !auctionProducts[0]
                                                                        .future_active ? (
                                                                        <>
                                                                            {auctionProducts[0]
                                                                                .auction ? (
                                                                                <>
                                                                                    <ReverseBidding
                                                                                        data={
                                                                                            auctionProducts[0]
                                                                                        }
                                                                                        type={
                                                                                            'proxy'
                                                                                        }
                                                                                        accept_rejected={
                                                                                            auctionProducts[0]
                                                                                                .enable_duplicate
                                                                                        }
                                                                                        accept_non_winning={
                                                                                            auctionProducts[0]
                                                                                                .custom_auctionlot_enable_non_winning
                                                                                        }
                                                                                    />
                                                                                    <p className="bdInfoTxt">
                                                                                        Proxy Bid -
                                                                                        System will
                                                                                        automatically
                                                                                        bid for you
                                                                                        at the bid
                                                                                        increment
                                                                                        when others
                                                                                        outbid you
                                                                                        down to your
                                                                                        proxy bid
                                                                                        value. If
                                                                                        outbid, you
                                                                                        will be
                                                                                        notified, if
                                                                                        you allow
                                                                                        notifications
                                                                                        in settings.
                                                                                    </p>
                                                                                </>
                                                                            ) : null}
                                                                        </>
                                                                    ) : auctionDetails?.prebid_available ? (
                                                                        <PreBidding
                                                                            data={
                                                                                auctionProducts[0]
                                                                            }
                                                                            type={'proxy'}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="rtPdtContainer bgBckWrp bdHstWrp">
                                                    <h4 className="d-flex align-items-center mb-3">
                                                        <span className="material-icons mr-2">
                                                            history
                                                        </span>
                                                        Bid History
                                                    </h4>
                                                    <BidHistoryComp
                                                        id={auctionProducts[0].id}
                                                        refreshData={reloadBidHistory}
                                                        noHeader={true}
                                                        noPagination={true}
                                                        hideName={
                                                            auctionProducts[0]
                                                                .custom_auctionlot_isname_visible ===
                                                            0
                                                                ? true
                                                                : false
                                                        }
                                                        hideValue={
                                                            auctionProducts[0]
                                                                .custom_auctionlot_isbidvalue_visible ===
                                                            0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="bgBckWrp mb-2 mltiActWrpr">
                                                <div className="actnDtlWrpr">
                                                    <div className="acContent">
                                                        <h2 className="actnTtrl mb-2 d-flex align-items-center justify-content-between">
                                                            {auctionDetails.title}
                                                            {isAuthenticated ? (
                                                                <div>
                                                                    <input
                                                                        id={project_id}
                                                                        type="checkbox"
                                                                        checked={checked}
                                                                        hidden
                                                                        onChange={toggleFavourite}
                                                                    />
                                                                    {checked ? (
                                                                        <label
                                                                            className="adFavLable"
                                                                            htmlFor={project_id}
                                                                        >
                                                                            <span className="material-icons">
                                                                                favorite
                                                                            </span>{' '}
                                                                            Added to favorites
                                                                        </label>
                                                                    ) : (
                                                                        <label
                                                                            className="adFavLable"
                                                                            htmlFor={project_id}
                                                                        >
                                                                            <span className="material-icons">
                                                                                favorite_border
                                                                            </span>{' '}
                                                                            Add to favorites
                                                                        </label>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <label
                                                                    className="adFavLable"
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            'login',
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        favorite_border
                                                                    </span>{' '}
                                                                    Add to favorites
                                                                </label>
                                                            )}
                                                        </h2>
                                                        <h6
                                                            dangerouslySetInnerHTML={{
                                                                __html: auctionDetails.description,
                                                            }}
                                                        ></h6>
                                                    </div>
                                                    <div className="lctnChipWrp">
                                                        <span className="material-icons">
                                                            place
                                                        </span>
                                                        {allLocations
                                                            .filter(
                                                                (value) =>
                                                                    auctionDetails.location_id ===
                                                                    value.id,
                                                            )
                                                            .map((location) => (
                                                                <span key={location.id}>
                                                                    {location.name}
                                                                </span>
                                                            ))}
                                                    </div>
                                                    <div className="grdCntnr text-left mt-4">
                                                        <div className="d-flex align-items-center">
                                                            <label className="m-0">
                                                                Started On:
                                                            </label>
                                                            <p className="m-0">
                                                                {dateFormatFront(
                                                                    auctionDetails.date_added,
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <label className="m-0">Ends On:</label>
                                                            <p className="m-0">
                                                                {auctionDetails
                                                                    ? dateFormatFront(
                                                                          auctionDetails.date_closed,
                                                                      )
                                                                    : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LiveAuction
